import classNames from 'classnames';
import React from 'react';
import t from '../../../locales/translations.config';
import './ProfilePicture.scss';
import { FaMediumM, FaLinkedinIn, FaGithub } from 'react-icons/fa';

const links = [
  // {
  //   name: 'portfolio',
  //   url: '#',
  //   Icon: FaListUl,
  //   target: '_self',
  // },
  {
    name: 'linkedin',
    url: 'https://www.linkedin.com/in/johannes-hinterstoesser/',
    Icon: FaLinkedinIn,
    target: '_blank',
  },
  {
    name: 'medium',
    url: 'https://medium.com/@jhinter',
    Icon: FaMediumM,
    target: '_blank',
  },
  {
    name: 'github',
    url: 'https://github.com/jhinter',
    Icon: FaGithub,
    target: '_blank',
  },
];

type ProfilePictureProps = {
  selected: string;
  handleSelect: (e: any, profileName: any) => void;
};

const ProfilePicture: React.FC<ProfilePictureProps> = ({
  selected,
  handleSelect,
}: ProfilePictureProps) => {
  const bubbleList = () => {
    const liElements = links.map((profile) => (
      <li key={profile.name}>
        <a
          onClick={(e) => handleSelect(e, profile.name)}
          className={classNames('flex-container', {
            active: selected === profile.name,
          })}
          rel="noopener noreferrer"
          id={profile.name}
          href={profile.url}
          target={profile.target}
        >
          <profile.Icon />
        </a>
      </li>
    ));
    return <ul className="circle-container">{liElements}</ul>;
  };

  return (
    <section className="profil">
      {bubbleList()}
      <div>
        <h1>Johannes Hinterstößer</h1>
        <h2>Senior Software Engineer</h2>
      </div>
    </section>
  );
};

export default ProfilePicture;
