import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { richSnippetPerson } from '../components/seo/rich-snippets';
import Layout from '../components/ui/layout/layout';
import { ParticlesBackground } from '../components/ui/particles/ParticlesBackground';
import ProfilePicture from '../components/ui/profile-picture/ProfilePicture';
import useWindowSize from '../hooks/useWindowSize';

const layoutConf = {
  siteTitle: 'Senior Software Engineer',
  hideHeader: true,
  fixedFooter: true,
  structuredData: richSnippetPerson,
};

const ProfilePage: React.FC = () => {
  const size = useWindowSize();
  const [selected, setSelected] = useState('');

  const handleSelect = (e, newSelected) => {
    setSelected(newSelected);
  };

  return (
    <Layout {...layoutConf}>
      <Helmet>
        <html className="profil"> </html>
      </Helmet>
      <ParticlesBackground key={size.width} />
      <div className="wrapper">
        <ProfilePicture selected={selected} handleSelect={handleSelect} />
      </div>
    </Layout>
  );
};

export default ProfilePage;
