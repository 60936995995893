import React from 'react';
import Particles from 'react-particles-js';

const style = {
  position: 'absolute',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
  background: `linear-gradient(
    to right,
    #2c3e50,
    #2980b9
  )`,
};

const params = {
  particles: {
    number: {
      value: 35,
    },
    color: {
      value: '#ffffff',
    },
    opacity: {
      value: 0.2,
      random: false,
      anim: {
        enable: false,
        speed: 1,
        opacity_min: 0.1,
        sync: false,
      },
    },
    size: {
      value: 5,
      random: true,
      anim: {
        enable: false,
        speed: 1,
        size_min: 0.1,
        sync: false,
      },
    },
  },
  interactivity: {
    events: {
      onhover: {
        enable: true,
        mode: 'repulse',
      },
    },
  },
};

export const ParticlesBackground: React.FC = () => {
  return <Particles style={style} params={params as any}></Particles>;
};
