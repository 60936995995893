export const richSnippetPerson = {
  '@context': 'http://schema.org/',
  '@type': 'Person',
  name: 'Johannes Hinterstößer',
  jobTitle: 'Senior Software Engineer',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'München',
    addressRegion: 'Germany',
  },
  image: [
    '/images/avatar/1x1.png',
    '/images/avatar/4x3.png',
    '/images/avatar/16x9.png',
  ],
};
